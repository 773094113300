<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <input type="text" class="form-control" v-show="!component_to_label" v-bind:class="{ 'is-invalid': component_message != '' && component_message != undefined}" v-bind:id="component_id"
    v-bind:name="component_meta.variable" v-bind:value="component_value" @change="input_event" />
  <div class="invalid-mssage">{{ component_message }}</div>

  <div v-show="component_to_label">
    {{ component_value }}
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let localValue = this.value;
    if (localValue == undefined) {
      localValue = this.field.component.properties.default;
    }

    let localMeta = this.field;
    if (localMeta.component.properties.show_label == undefined) {
      localMeta.component.properties.show_label = '';
    }

    return {
      // 元件識別碼
      component_id: 'text-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: localMeta,

      // 元件值
      component_value: localValue,

      // 元件訊息
      component_message: this.message,

      // 元件是否被標籤化
      component_to_label: (localMeta.component.properties.to_label == 'Y') ? true : false,
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {
    input_event: function ($event) {
      this.component_value = $event.target.value;
      this.$emit('input', $event)
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
</style>